import { Form, useFetcher, useLocation } from "@remix-run/react";

export default function LanguageSwitcher({ locale, sy }: { locale: string, sy: string }) {
  const location = useLocation();


  return (
    <div className="print:hidden">
      <Form
        // navigate={false}
        // fetcherKey="lng"
        action={location.pathname}
        className="absolute top-0 right-0 text-white mr-2"
      >
        <label htmlFor="locale" className=" font-light text-sm pr-2">
          Change Language:
        </label>
        <input
          type="hidden"
          name="sy"
          value={sy}
        />
        <button
          className=" disabled:hidden font-semibold transform transition-transform ease-in duration-200 hover:scale-110"
          disabled={locale === "en" || locale === "en-us"}
          value="en"
          name="lng"
          type='submit'
        >
          English
        </button>
        <button
          type='submit'
          className="disabled:hidden font-semibold transform transition-transform ease-in duration-200 hover:scale-110"
          disabled={locale === "es"}
          value="es"
          name="lng"
        >
          Spanish
        </button>
      </Form>
    </div>
  );
}
